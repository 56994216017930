//
// import React, { useState, useEffect,useRef } from 'react';
// import Plot from 'react-plotly.js';
// import { Box, Typography, TextField, CircularProgress, IconButton, Avatar, Dialog, DialogContent } from '@mui/material';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import SendRoundedIcon from '@mui/icons-material/SendRounded';
// import CloseIcon from '@mui/icons-material/Close';
// import Cookies from 'js-cookie';
// import '../CSS/Chatbot.css';
// import { aiCall } from '../utils/Network';
// import botIcon from '../Assets/BOT.png';
// import AIbotIcon from '../Assets/BOT AI.png';
// import UserIcon from '../Assets/USER.png';
//
// const Chatbot = () => {
//     const [question, setQuestion] = useState('');
//     const [answer, setAnswer] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [chatHistory, setChatHistory] = useState([]);
//     const [isChatOpen, setIsChatOpen] = useState(false);
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [enlargedPlotData, setEnlargedPlotData] = useState(null);
//     const chatContainerRef = useRef(null);
//
//     useEffect(() => {
//         const isLogin = Cookies.get('isLogin') === 'true';
//         setIsLoggedIn(isLogin);
//
//         setChatHistory([{
//             question: (
//                 <>
//                     Welcome to BIL Launchbot!<br />
//                     You can inquire about any aspect of the NOFO, program, and project.
//                 </>
//             ),
//             isIntro: true
//         }]);
//     }, []);
//
//     useEffect(() => {
//         if (chatContainerRef.current) {
//             chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//         }
//     }, [chatHistory]);
//
//     const handleSendMessage = () => {
//         if (!question) return;
//
//         setLoading(true);
//
//         setChatHistory((prevHistory) => [
//             ...prevHistory,
//             { question },
//         ]);
//
//         aiCall
//             .post('/chatbot/', { question })
//             .then((res) => {
//                 const response = res.data;
//
//                 setTimeout(() => {
//                     setChatHistory((prevHistory) => [
//                         ...prevHistory.slice(0, -1),
//                         { question, answer: response },
//                     ]);
//                     setAnswer(response);
//                 }, 500);
//             })
//             .catch((err) => {
//                 console.error("Error fetching response:", err);
//                 setAnswer('Failed to fetch response. Please try again.');
//             })
//             .finally(() => {
//                 setLoading(false);
//             });
//
//         setQuestion('');
//     };
//
//     // Parse and render the plot
//     const renderPlot = (plotData, isSmall) => {
//         try {
//
//             const parsedData = typeof plotData === 'string' ? JSON.parse(plotData) : plotData;
//
//
//             if (parsedData && parsedData.data && parsedData.layout) {
//                 return (
//                     <Box
//                         onClick={() => {
//                             if (isSmall) {
//                                 setEnlargedPlotData(parsedData);
//                                 setIsModalOpen(true);
//                             }
//                         }}
//                         sx={{
//                             cursor: isSmall ? 'pointer' : 'auto',
//                             maxWidth: isSmall ? '100%' : 'auto',
//                         }}
//                     >
//                         <Plot
//                             data={parsedData.data}
//                             layout={parsedData.layout}
//                             style={{ width: isSmall ? '100%' : '100%' }}
//                         />
//                     </Box>
//                 );
//             }
//         } catch (err) {
//             console.error('Error parsing plot data:', err);
//         }
//         return null;
//     };
//
//     if (!isLoggedIn) {
//         return null;
//     }
//
//     return (
//         <Box className="chatbot-wrapper">
//             {!isChatOpen ? (
//                 <IconButton
//                     onClick={() => setIsChatOpen(true)}
//                     className="chat-icon-button"
//                     sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
//                 >
//                     <img src={AIbotIcon} style={{ width: 40, height: 40 }} alt="Chatbot Icon" />
//                     <Typography variant="body2" sx={{ marginTop: '5px' }}>BIL Launchbot</Typography>
//                 </IconButton>
//             ) : (
//                 <Box className="chatbot-container">
//                     <Box
//                         className="chat-header"
//                         sx={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             padding: '10px',
//                             borderBottom: '1px solid #ddd'
//                         }}
//                     >
//                         <Typography variant="h6">Chat with BIL Launchbot</Typography>
//                         <IconButton
//                             onClick={() => setIsChatOpen(false)}
//                             sx={{ position: 'absolute', right: 10 }}
//                         >
//                             <CloseIcon />
//                         </IconButton>
//                     </Box>
//
//                     {/* Chat History */}
//                     <Box className="chat-container" ref={chatContainerRef}>
//                         <TransitionGroup>
//                             {chatHistory.map((chat, index) => (
//                                 <CSSTransition
//                                     key={index}
//                                     timeout={500}
//                                     classNames="fade"
//                                 >
//                                     <Box className="chat-message-pair">
//                                         <Box className="user-message">
//                                             {chat.isIntro ? (
//                                                 <Box className="message-bubble intro-bubble">
//                                                     <Typography variant="body1">
//                                                         {chat.question}
//                                                     </Typography>
//                                                 </Box>
//                                             ) : (
//                                                 <Box className="message-bubble user-bubble">
//                                                     <Typography variant="body1">
//                                                         {chat.question}
//                                                     </Typography>
//                                                 </Box>
//                                             )}
//                                             {!chat.isIntro && (
//                                                 <Avatar className="user-avatar" sx={{ bgcolor: 'transparent' }}>
//                                                     <img src={UserIcon} alt="User Avatar" style={{ width: '100%', height: '100%' }} />
//                                                 </Avatar>
//                                             )}
//                                         </Box>
//                                         {chat.answer && (
//                                             <CSSTransition
//                                                 in={!!chat.answer}
//                                                 timeout={500}
//                                                 classNames="fade"
//                                             >
//                                                 <Box className="bot-message">
//                                                     <Avatar className="bot-avatar">
//                                                         <img src={botIcon} alt="Bot Avatar" style={{ width: '100%', height: '100%' }} />
//                                                     </Avatar>
//                                                     <Box className="message-bubble bot-bubble">
//
//                                                         {/*{chat.answer.answer && (*/}
//                                                         {/*    <Typography variant="body1" style={{ whiteSpace: 'pre-wrap', marginBottom: '10px' }}>*/}
//                                                         {/*        {chat.answer.answer}*/}
//                                                         {/*    </Typography>*/}
//                                                         {/*)}*/}
//
//                                                         {chat.answer.answer && (
//                                                             <Typography variant="body1" style={{ whiteSpace: 'pre-wrap', marginBottom: '10px' }}>
//                                                                 {chat.answer.answer.split('->').map((line, index) => (
//                                                                     <React.Fragment key={index}>
//                                                                         {index > 0 && '->'}
//                                                                         {line.trim()}
//                                                                         <br />
//                                                                     </React.Fragment>
//                                                                 ))}
//                                                             </Typography>
//                                                         )}
//
//
//                                                         {/*{chat.answer.plot && renderPlot(chat.answer.plot, true)}*/}
//
//                                                         {chat.answer.plot && (
//                                                             <Box>
//                                                                 <Typography
//                                                                     variant="body1"
//                                                                     style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
//                                                                     onClick={() => {
//                                                                         setEnlargedPlotData(chat.answer.plot);
//                                                                         setIsModalOpen(true);
//                                                                     }}
//                                                                 >
//                                                                     Click here for visualization
//                                                                 </Typography>
//                                                             </Box>
//                                                         )}
//                                                     </Box>
//                                                 </Box>
//                                             </CSSTransition>
//                                         )}
//                                     </Box>
//                                 </CSSTransition>
//                             ))}
//                         </TransitionGroup>
//                         {loading && (
//                             <Box className="loading-spinner" textAlign="center" mt={2}>
//                                 <CircularProgress />
//                             </Box>
//                         )}
//                     </Box>
//
//                     <Box className="message-prompt" mt={2}>
//                         <TextField
//                             fullWidth
//                             multiline
//                             rows={1.5}
//                             maxRows={4}
//                             variant="outlined"
//                             placeholder="Type your question"
//                             value={question}
//                             onChange={(e) => setQuestion(e.target.value)}
//                             InputProps={{
//                                 endAdornment: (
//                                     <IconButton onClick={handleSendMessage} color="primary">
//                                         <SendRoundedIcon />
//                                     </IconButton>
//                                 ),
//                             }}
//                             sx={{
//                                 overflowY: 'auto',
//                             }}
//                         />
//                     </Box>
//
//
//                     {/*<Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="lg" fullWidth>*/}
//                     {/*    <DialogContent>*/}
//                     {/*        {enlargedPlotData && renderPlot(enlargedPlotData, false)}*/}
//                     {/*    </DialogContent>*/}
//                     {/*</Dialog>*/}
//
//                     <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="lg" fullWidth>
//                         <DialogContent>
//                             <IconButton
//                                 onClick={() => {
//                                     setIsModalOpen(false);
//                                     setEnlargedPlotData(null);
//                                 }}
//                                 style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
//                             >
//                                 <CloseIcon />
//                             </IconButton>
//                             {enlargedPlotData && renderPlot(enlargedPlotData, false)}
//                         </DialogContent>
//                     </Dialog>
//
//                 </Box>
//             )}
//         </Box>
//     );
// };
//
// export default Chatbot;
//
//
//
//
//
//



import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import { Box, Typography, TextField, CircularProgress, IconButton, Avatar, Dialog, DialogContent } from '@mui/material';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import '../CSS/Chatbot.css';
import { aiCall } from '../utils/Network';
import botIcon from '../Assets/BOT.png';
import AIbotIcon from '../Assets/BOT AI.png';
import UserIcon from '../Assets/USER.png';

const Chatbot = () => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [enlargedPlotData, setEnlargedPlotData] = useState(null);
    const chatContainerRef = useRef(null);


    useEffect(() => {
        const isLogin = Cookies.get('isLogin') === 'true';  
        setIsLoggedIn(isLogin);

        if (isLogin) {
            const savedChatHistory = sessionStorage.getItem('chatHistory');
            const savedIsChatOpen = sessionStorage.getItem('isChatOpen') === 'true';

            if (savedChatHistory) {
                setChatHistory(JSON.parse(savedChatHistory));
            }

            setIsChatOpen(savedIsChatOpen);
        }
    }, []);


    useEffect(() => {
        if (chatHistory.length > 0) {
            sessionStorage.setItem('chatHistory', JSON.stringify(chatHistory));
        }
    }, [chatHistory]);

    useEffect(() => {
        sessionStorage.setItem('isChatOpen', isChatOpen);
    }, [isChatOpen]);


    useEffect(() => {
        if (!isLoggedIn) {
            sessionStorage.removeItem('chatHistory');
            sessionStorage.removeItem('isChatOpen');

        }
    }, [isLoggedIn]);


    useEffect(() => {
        const checkLogin = () => {
            const isLogin = Cookies.get('isLogin') === 'true';
            setIsLoggedIn(isLogin);
        };

        const interval = setInterval(checkLogin, 1000); 
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    const handleSendMessage = () => {
        if (!question) return;

        setLoading(true);

        setChatHistory((prevHistory) => [
            ...prevHistory,
            { question },
        ]);

        aiCall
            .post('/chatbot/', { question })
            .then((res) => {
                const response = res.data;

                setTimeout(() => {
                    setChatHistory((prevHistory) => [
                        ...prevHistory.slice(0, -1),
                        { question, answer: response },
                    ]);
                    setAnswer(response);
                }, 500);
            })
            .catch((err) => {
                console.error("Error fetching response:", err);
                setAnswer('Failed to fetch response. Please try again.');
            })
            .finally(() => {
                setLoading(false);
            });

        setQuestion('');
    };


    const renderPlot = (plotData, isSmall) => {
        try {
            const parsedData = typeof plotData === 'string' ? JSON.parse(plotData) : plotData;

            if (parsedData && parsedData.data && parsedData.layout) {
                return (
                    <Box
                        onClick={() => {
                            if (isSmall) {
                                setEnlargedPlotData(parsedData);
                                setIsModalOpen(true);
                            }
                        }}
                        sx={{
                            cursor: isSmall ? 'pointer' : 'auto',
                            maxWidth: isSmall ? '100%' : 'auto',
                        }}
                    >
                        <Plot
                            data={parsedData.data}
                            layout={parsedData.layout}
                            style={{ width: isSmall ? '100%' : '100%' }}
                        />
                    </Box>
                );
            }
        } catch (err) {
            console.error('Error parsing plot data:', err);
        }
        return null;
    };


    if (!isLoggedIn) {
        return null;
    }

    return (
        <Box className="chatbot-wrapper">
            {!isChatOpen ? (
                <IconButton
                    onClick={() => setIsChatOpen(true)}
                    className="chat-icon-button"
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <img src={AIbotIcon} style={{ width: 40, height: 40 }} alt="Chatbot Icon" />
                    <Typography variant="body2" sx={{ marginTop: '5px' }}>BIL Launchbot</Typography>
                </IconButton>
            ) : (
                <Box className="chatbot-container">
                    <Box
                        className="chat-header"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',
                            borderBottom: '1px solid #ddd'
                        }}
                    >
                        <Typography variant="h6">Chat with BIL Launchbot</Typography>
                        <IconButton
                            onClick={() => setIsChatOpen(false)}
                            sx={{ position: 'absolute', right: 10 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Chat History */}
                    <Box className="chat-container" ref={chatContainerRef}>
                        <TransitionGroup>
                            {/* Display the welcome message */}
                            <CSSTransition
                                key="welcome-message"
                                timeout={500}
                                classNames="fade"
                            >
                                <Box className="chat-message-pair">
                                    <Box className="user-message">
                                        <Box className="message-bubble intro-bubble">
                                            <Typography variant="body1">
                                                Welcome to BIL Launchbot!<br />
                                                You can inquire about any aspect of the NOFO, program, and project.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </CSSTransition>
                            {/* Display the rest of the chat history */}
                            {chatHistory.map((chat, index) => (
                                <CSSTransition
                                    key={index}
                                    timeout={500}
                                    classNames="fade"
                                >
                                    <Box className="chat-message-pair">
                                        <Box className="user-message">
                                            <Box className="message-bubble user-bubble">
                                                <Typography variant="body1">
                                                    {chat.question}
                                                </Typography>
                                            </Box>
                                            <Avatar className="user-avatar" sx={{ bgcolor: 'transparent' }}>
                                                <img src={UserIcon} alt="User Avatar" style={{ width: '100%', height: '100%' }} />
                                            </Avatar>
                                        </Box>
                                        {chat.answer && (
                                            <CSSTransition
                                                in={!!chat.answer}
                                                timeout={500}
                                                classNames="fade"
                                            >
                                                <Box className="bot-message">
                                                    <Avatar className="bot-avatar">
                                                        <img src={botIcon} alt="Bot Avatar" style={{ width: '100%', height: '100%' }} />
                                                    </Avatar>
                                                    <Box className="message-bubble bot-bubble">
                                                        {chat.answer.answer && (
                                                            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap', marginBottom: '10px' }}>
                                                                {chat.answer.answer.split('->').map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {index > 0 && '->'}
                                                                        {line.trim()}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {chat.answer.plot && (
                                                            <Box>
                                                                <Typography
                                                                    variant="body1"
                                                                    style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                                                                    onClick={() => {
                                                                        setEnlargedPlotData(chat.answer.plot);
                                                                        setIsModalOpen(true);
                                                                    }}
                                                                >
                                                                    Click here for visualization
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </CSSTransition>
                                        )}
                                    </Box>
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                        {loading && (
                            <Box className="loading-spinner" textAlign="center" mt={2}>
                                <CircularProgress />
                            </Box>
                        )}
                    </Box>

                    <Box className="message-prompt" mt={2}>
                        <TextField
                            fullWidth
                            multiline
                            rows={1.5}
                            maxRows={4}
                            variant="outlined"
                            placeholder="Type your question"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={handleSendMessage} color="primary">
                                        <SendRoundedIcon />
                                    </IconButton>
                                ),
                            }}
                            sx={{
                                overflowY: 'auto',
                            }}
                        />
                    </Box>

                    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="lg" fullWidth>
                        <DialogContent>
                            <IconButton
                                onClick={() => {
                                    setIsModalOpen(false);
                                    setEnlargedPlotData(null);
                                }}
                                style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            {enlargedPlotData && renderPlot(enlargedPlotData, false)}
                        </DialogContent>
                    </Dialog>

                </Box>
            )}
        </Box>
    );
};

export default Chatbot;
