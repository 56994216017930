// All requesting is asynchronous, so you must pass a data states parameter
// to get the result. Once the result is returned, the coresponding
// state will be updated

import axios from "axios";

// debug
// const BACKEND_URL = 'http://localhost:5000/api';
// dev
// const BACKEND_URL = "https://api-dev.billaunchpad.com/api";
// release
const BACKEND_URL = "https://api.billaunchpad.com/api";

const AI_URL = 'http://127.0.0.1:8000/chatbot/api';
// const AI_URL = 'https://ai.billaunchpad.com/chatbot/api';

// const AI_URL = 'http://54.204.210.117:8000/chatbot/api';

const SCORE_URL = "https://score.billaunchpad.com";

// const BACKEND_URL =
//   window.location.href.includes("dev.billaunchpad.com") ||
//   window.location.href.includes("localhost")
//     ? "https://api.billaunchpad.com/api"
//     : "https://api.billaunchpad.com/api";

export const backendCall = axios.create({
  baseURL: BACKEND_URL,
});


export const scoreCall = axios.create({
  baseURL: SCORE_URL,
  // timeout: 1000,
  headers: {
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});


export const aiCall = axios.create({
  baseURL: AI_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});