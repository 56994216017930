import React, { Component } from 'react';
import YouTube from 'react-youtube';

class YoutubeVideo extends Component {
    constructor(props) {
        super(props);

        // Set the initial state with the video ID
        this.state = {
            videoId: 'm_34JYFMRwQ',
            playerOptions: {
                width: '820',
                height: '480',
            },
        };
    }

    render() {
        const { videoId, playerOptions } = this.state;

        return (
            <div>
                <YouTube videoId={videoId} opts={playerOptions} />
            </div>
        );
    }
}

export default YoutubeVideo;